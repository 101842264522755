<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-2" size="xl" title="Pencairan Dana" ok-only ok-title="Accept" hide-footer hide-header-close
            no-close-on-backdrop>
            <b-row>
                <b-col md ="10">

                </b-col>
                <b-col md="2">
                    <b-button block variant="warning" size="sm" @click="close()"><feather-icon icon ='XCircleIcon' size="15"></feather-icon>Keluar</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="5">
                    <small><strong>{{detailPaket.kode_dinas }} </strong><br>
                        <h6>{{detailPaket.nama_dinas }}</h6>
                    </small>
                    <small>Nama Sub Kegiatan </small>
                    <h6> {{detailPaket.kode_sub_kegiatan }} - {{detailPaket.nama_sub_kegiatan }}</h6>

                </b-col>
                <b-col md="7">
                    <b-row>
                        <b-col md="6">
                            <small>Nama Rekanan </small>
                            <h6><strong>{{detailPaket.nama_rekanan }} </strong><br>
                                <small>Tgl Kontrak {{detailPaket.tgl_kontrak }} </small></h6>
                            <h6><small>Type Kegiatan</small><br>Kegiatan {{detailPaket.jenis_kegiatan }}</h6>

                        </b-col>
                        <b-col md="6">
                            <small>Lokasi Sub Kegiatan </small>
                            <h6>{{detailPaket.nm_wil }} </h6>
                            <small>Jangka Waku Kontrak Kerja Mulai Dari </small>
                            <h6> {{detailPaket.tgl_mulai }} <br><small>Sampai Dengan</small>
                                <br>{{detailPaket.tgl_selesai }} </h6>

                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <h6><small>Keterangan</small></h6>
                            <p><small>{{ detailPaket.keterangan }}</small></p>
                        </b-col>
                    </b-row>

                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <h5>
                        <feather-icon icon="MenuIcon" size="15"></feather-icon> Pencairan Anggaran
                    </h5>
                    <b-alert show variant="primary">
                        <div class="alert-body">
                            <b-row>
                                <b-col md="4">
                                    <small class="">Nilai Kontrak</small>
                                    <h4 class="text-primary"> <sup>Rp.</sup>
                                        <strong> {{Rp(detailPaket.nilai_kontrak) }} </strong><small> </small></h4>
                                </b-col>
                                <b-col md="4">
                                    <small class="">Serapan Anggaran</small>
                                    <h4 class="text-primary"> <sup>Rp.</sup>
                                        <strong> {{Rp(parseInt(rincian_pencairan.total_pencaairan)) }} </strong><small>
                                        </small></h4>
                                </b-col>
                                <b-col md="4">
                                    <small class="">Sisa Anggaran</small>
                                    <h4 class="text-primary"> <sup>Rp.</sup>
                                        <strong>
                                            {{Rp(parseInt(detailPaket.nilai_kontrak) -  parseInt(rincian_pencairan.total_pencaairan)) }}</strong><small>
                                        </small></h4>
                                </b-col>
                            </b-row>
                        </div>
                    </b-alert> 
                    <b-alert show variant="warning" v-for="rs in rincian_pencairan.rs" v-bind:key="rs.id">
                        <div class="alert-body">
                            <b-row>
                                <b-col md="7">
                                    <h6><small>Judul</small></h6>
                                    <h6>{{ rs.judul }}</h6>
                                    <h6><small>Keterangan</small></h6>
                                    <h6>{{ rs.keterangan }}</h6>
                                    <h6><small>Tanggal Pencairan</small></h6>
                                    <h6>{{ rs.tgl_pencairan }}</h6>
                                </b-col>
                                <b-col md="5">


                                    <!-- <b-row>
                                        <b-col md="6">
                                            <h6> File SPJ </h6>
                                        </b-col>
                                        <b-col md="6">
                                           <b-badge variant="primary">
                                            <feather-icon icon='FileTextIcon' size="15"></feather-icon> Download SPJ
                                        </b-badge>
                                        </b-col>
                                    </b-row>  -->
                                    <h6><small>Nominal Pencairan</small></h6>
                                    <h1 class="text-primary"><strong><sup>Rp. </sup>{{ Rp(rs.nominal)}}, -</strong></h1>
                                    <h6><small>Jensi pembayaran</small> {{ rs.jenis_pencairan }}</h6>
                                    <b-row>
                                        <!-- <b-col md="6">
                                            <b-button variant="warning" block size="sm">
                                                <feather-icon icon='EditIcon' size='15'></feather-icon>
                                                Edit
                                            </b-button>
                                        </b-col> -->
                                        <b-col md="12"> 
                                            <b-button variant="danger" block size="sm" @click="delete_pengajaan(rs.id)" >
                                                <feather-icon icon='TrashIcon' size='15'></feather-icon>
                                                Delete
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>

                            </b-row>
                        </div>
                    </b-alert>


                </b-col>
                <b-col md="6">
                    <h5>
                        <feather-icon icon="PlusIcon" size="15"></feather-icon> Pengajuan Pencairan Anggaran
                    </h5>
                    <b-alert show variant="warning">
                        <div class="alert-body">
                            <validation-observer ref="simpleRules">
                                <b-form>
                                    <b-row>
                                        <b-col md="12">
                                            <b-form-group label="Judul Pengajuan">
                                                <validation-provider #default="{ errors }" name="Judul Pengajuan"
                                                    rules="required">
                                                    <b-form-input v-model="input.judul"
                                                        :state="errors.length > 0 ? false:null"
                                                        placeholder="Judul Pengajuan" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                            <b-row>
                                                <b-col md="6">
                                                    <b-form-group label="Tanggal pencairan">
                                                        <validation-provider #default="{ errors }"
                                                            name="Tanggal Pencairan" rules="required">
                                                            <b-form-datepicker id="example-datepicker"
                                                                v-model="input.tgl_pencairan"
                                                                :state="errors.length > 0 ? false:null" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group label="Jenis Pembayaran">
                                                        <validation-provider #default="{ errors }"
                                                            name="Judul Pengajuan" rules="required">
                                                            <b-form-select v-model="input.jenis_pencairan"
                                                                :options="options"
                                                                :state="errors.length > 0 ? false:null" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-form-group label="Total Pengajuan Pencairan Rp. ">
                                                <validation-provider #default="{ errors }" name="Pencairan Dana "
                                                    rules="required">
                                                    <CurrencyInput class="form-control" v-model="input.nominal" />
                                                    <!-- // :state='errors.length > 0 ? false:null' -->
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                            </b-form-group>

                                            <b-form-group label="Keterangan">
                                                <b-form-textarea id="textarea-default" v-model="input.keterangan"
                                                    placeholder="Keterangan Pengajuan" rows="3" />
                                            </b-form-group>

                                        </b-col>

                                    </b-row>
                                    <hr>
                                    <b-row>
                                        <b-col cols="12">
                                            <b-button variant="primary" type="submit" @click.prevent="validationForm">
                                                Submit
                                            </b-button>
                                            <b-button variant="warning" @click="batal()" class="ml-1">
                                                Batal
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import flatPickr from 'vue-flatpickr-component'
    import {
        required
    } from '@validations'
    import Base from '@/config/Mixins_base'
    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormTextarea,
        BCardText,
        BFormDatepicker,
        BBadge,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    import CurrencyInput from './CurrencyInput.vue'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
    import axios from '@/config/Axios';

    export default {
        components: {
            BButton,
            ValidationProvider,
            ValidationObserver,
            BModal,
            BAlert,
            BRow,
            BCol,
            vSelect,
            BFormGroup,
            BFormInput,
            BFormSelect,
            CurrencyInput,
            BFormTextarea,
            BCardText,
            BFormDatepicker,
            FeatherIcon,
            BBadge,
            flatPickr
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return {
                input: {},
                options: [{
                        value: 'GU',
                        text: 'GU'
                    },
                    {
                        value: 'UP',
                        text: 'UP'
                    },
                    {
                        value: 'LS',
                        text: 'LS'
                    },
                ],
            }
        },
        mixins: [Base],
        props: {
            PaketDetail: {},
            pencairanRincian: {}
        },

        computed: {
            detailPaket: {
                get: function () {
                    return this.PaketDetail;
                },
                set: function (newValue) {
                    return this.PaketDetail = newValue;
                },
            },
            rincian_pencairan: {
                get: function () {
                    return this.pencairanRincian;
                },
                set: function (newValue) {
                    return this.pencairanRincian = newValue;
                },
            }
        },
emits : ["load"],
        methods: {
            batal() {
                this.input = {}
            },

            async insert() {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/input/pengajuan/insert',
                        data: {
                            id_paket: self.detailPaket.id_paket,
                            id_paket_kontrak: self.detailPaket.id,
                            judul : self.input.judul,
                            keterangan : self.input.keterangan,
                            tgl_pencairan : self.input.tgl_pencairan,
                            file_spj : self.input.file_spj,
                            jenis_pencairan : self.input.jenis_pencairan,
                            nominal : self.input.nominal,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs = response.data.result;
                        self.detail_pengajuan(self.detailPaket.id);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async detail_pengajuan(id) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/input/pengajuan/detail_pengajuan',
                        data: {
                            id: id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rincian_pencairan = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async delete_pengajaan(id) {
                    if(confirm("apakah anda yakin akan menghapus data ini..?") == true){
                        const self = this;
                    await axios({
                        method: 'POST',
                        url: '/api/input/pengajuan/delete_pengajaan',
                        data: {
                            id : id
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.rs = response.data.result;
                         self.detail_pengajuan(self.detailPaket.id);            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                    }
                  },
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.insert(); 
                        this.input  = {}
                    }
                })
            },

            close(){
                this.$root.$emit('bv::hide::modal', 'modal-2');
                this.$emit("load");
            }
        },
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>