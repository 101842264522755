<template>
  <div>
    <b-card class="bg-light-primary">
      <b-row style="margin-top :-10px; margin-bottom :-20px">
        <b-col md="9">
          <b-form-group>
            <v-select v-model="id_dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas"
              @input="loadData()" :options="opd" />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-button block variant="warning" @click="Refresh()">
            <feather-icon size="15" icon="RefreshCwIcon" /> Refresh
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <daftar-paket :items=items @detailData =detailData></daftar-paket>
    </b-card>
    <pencairandana :PaketDetail = detailPaket :pencairanRincian = rincian_pencairan @load = load></pencairandana>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base'
  import axios from '@/config/Axios'
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import DaftarPaket from './componet/DaftarPaket.vue'
  import pencairandana from './componet/pencairandana.vue'
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BFormInput,
      BFormGroup,
      vSelect,
      DaftarPaket,
      pencairandana,
    },
    mixins: [Base],
    data() {
      return {
        opd: [],
        id_master_kode_dinas: "",
        id_dinas: {},
        items: [],
        daftar_paket: [],
        rekanan: [],
        detailPaket : {},
        rincian_pencairan : []
      }
    },
    mounted() {
      this.daftar_opd();
      this.load();  
    },
    methods: {
      loadData() {
        this.id_master_kode_dinas = this.id_dinas.id_master_kode_dinas;
        this.load();
      },
      Refresh() {
        this.id_master_kode_dinas = '';
        this.id_dinas = {}
        this.load();
      },
    
      detailData(value){ 
        this.detailPaket = value;
        this.detail_pengajuan(value.id);
      },
      async detail_pengajuan(id) {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/input/pengajuan/detail_pengajuan',
            data: {
              id : id
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) {
            self.rincian_pencairan = response.data.result; 
      
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
      async load() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/input/pengajuan/load',
            data: {
              id_master_kode_dinas: self.id_master_kode_dinas
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async daftar_opd() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/input/data_paket/daftar_opd',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.opd = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>